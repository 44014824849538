<template>
  <div>
    <ApAlert
      v-if="isInvalidOperator"
      type="warning"
      title="Configurations Conflicts"
      :text="`The selected field does not support the operator '${value.operator}' currently assigned to it in the database.`"
      class="mb-1"
    />

    <div class="d-flex justify-end">
      <v-btn
        text
        small
        outlined
        class="text-none font-weight-regular ma-2"
        @click="$emit('delete')"
      >
        <v-icon left> $mdi-delete-outline </v-icon>
        Delete filter
      </v-btn>
    </div>

    <div class="px-6 mt-4">
      <v-row dense>
        <v-col cols="6">
          <v-select
            label="Field"
            :value="value.field"
            :rules="fieldRules"
            outlined
            dense
            required
            :items="fields"
            item-value="name"
            item-text="label"
            append-icon="$mdi-chevron-down"
            color="accent"
            return-object
            @change="fieldChanged"
          />
        </v-col>

        <v-col cols="6">
          <v-select
            label="Operator"
            :value="value.operator"
            :rules="operatorRules"
            outlined
            dense
            required
            :items="selectedField ? selectedField.operators : []"
            :disabled="!selectedField"
            item-value="operator"
            item-text="label"
            append-icon="$mdi-chevron-down"
            color="accent"
            return-object
            @change="operatorChanged"
          />
        </v-col>
      </v-row>

      <v-row v-if="selectedOperator" dense>
        <v-col cols="12">
          <div v-if="!Array.isArray(selectedOperator.values)">
            <v-text-field
              :value="value.value"
              dense
              outlined
              label="Value"
              @change="valueChanged"
            />
          </div>

          <template v-else-if="selectedOperator.operator === 'in'">
            <div class="d-flex justify-space-between">
              <div class="mb-3 font-weight-medium">Values</div>
              <v-btn
                v-if="selectedOperator && selectedOperator.operator === 'in'"
                text
                small
                color="primary"
                class="ap-blue--text text-none text-decoration-none font-weight-regular"
                @click="addNewGroupOfValues"
              >
                <v-icon left> $mdi-plus </v-icon>
                Or
              </v-btn>
            </div>

            <template v-if="value.value && Array.isArray(value.value[0])">
              <div v-for="(group, index) in value.value" :key="index">
                <SegmentationListItemValue
                  is-multiple
                  :value="group"
                  :rules="multipleValuesRule"
                  :items="selectedOperator ? selectedOperator.values : []"
                  :is-disabled="!selectedOperator"
                  :is-delete-visible="value.value.length > 1"
                  :is-or-visible="value.value.length > 1"
                  @input="valueChanged($event, index)"
                  @delete="deleteGroupOfValues(index)"
                />
              </div>
            </template>

            <SegmentationListItemValue
              v-else
              is-multiple
              :value="value.value"
              :rules="multipleValuesRule"
              :items="selectedOperator ? selectedOperator.values : []"
              :is-disabled="!selectedOperator"
              @input="valueChanged"
            />
          </template>

          <SegmentationListItemValue
            v-else-if="['=', '!='].includes(selectedOperator.operator)"
            :value="value.value"
            :items="selectedOperator ? selectedOperator.values : []"
            :is-disabled="!selectedOperator"
            :rules="singleValueRule"
            @input="valueChanged"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ApAlert from '@/components/common/ApAlert'
import SegmentationListItemValue from './SegmentationListItemValue'

export default {
  components: {
    ApAlert,
    SegmentationListItemValue,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedField: null,
      selectedOperator: null,
      fieldRules: [(v) => !!v || 'Field is required'],
      operatorRules: [(v) => !!v || 'Operator is required'],
      singleValueRule: [(v) => !!v || 'Value is required'],
      multipleValuesRule: [(v) => (!!v && v.length > 0) || 'Value is required'],
    }
  },
  computed: {
    isInvalidOperator() {
      return this.value.operator && !this.selectedOperator
    },
  },
  created() {
    if (this.value.field) {
      this.selectedField = this.fields.find(
        (field) => field.name === this.value.field
      )
      this.selectedOperator = this.selectedField?.operators.find(
        (operator) => operator.operator === this.value.operator
      )
    }
  },
  methods: {
    fieldChanged(value) {
      this.selectedField = value
      this.$emit('input', {
        ...this.value,
        field: value.name,
        operator: null,
        value: null,
      })
    },
    operatorChanged(value) {
      this.selectedOperator = value

      switch (value.operator) {
        case 'in':
          this.$emit('input', {
            ...this.value,
            operator: value.operator,
            value: [],
          })
          break
        case '=':
          this.$emit('input', {
            ...this.value,
            operator: value.operator,
            value: null,
          })
          break
      }
    },
    addNewGroupOfValues() {
      const isListOfLists = Array.isArray(this.value.value?.[0])
      const value = isListOfLists
        ? [...this.value.value, []]
        : [[...this.value.value], []]

      this.$emit('input', {
        ...this.value,
        value,
      })
    },
    deleteGroupOfValues(index) {
      const valuesTemp = [...this.value.value]

      valuesTemp.splice(index, 1)

      this.$emit('input', {
        ...this.value,
        value: valuesTemp,
      })
    },
    valueChanged(value, index) {
      switch (this.selectedOperator.operator) {
        case 'in':
          this.updateMultipleValues(value, index)
          break
        default:
          this.$emit('input', {
            ...this.value,
            value,
          })
      }
    },
    updateMultipleValues(value, index) {
      const isListOfLists = index !== undefined

      if (isListOfLists) {
        const newValue = {
          ...this.value,
        }

        newValue.value[index] = value

        this.$emit('input', newValue)
      } else {
        this.$emit('input', {
          ...this.value,
          value,
        })
      }
    },
  },
}
</script>

<template>
  <ApLoading
    v-if="isLoading"
    class="mt-10"
    text="Loading Segmentation Rule..."
  />

  <RulesStatusError v-else-if="isError" @click="getSegmentationRule" />

  <div v-else class="pa-4" style="max-width: 600px">
    <div class="text-h5 font-weight-black mb-3">
      Tell us where to look for duplicates
    </div>

    <div class="text-body-2 mb-4">
      <v-icon small class="mr-1" color="primary">
        $mdi-information-outline
      </v-icon>
      How do you want to pull up the records?
    </div>

    <SegmentationList
      :rule="segmentationRule"
      class="mx-auto"
      @saved="$emit('saved')"
    />
  </div>
</template>

<script>
import ApLoading from '@/components/common/ApLoading'
import RulesStatusError from './RulesStatusError'
import SegmentationList from './SegmentationList'
import { mapActions, mapState } from 'vuex'
import { RULE_TYPES } from '@/api/business-rules/rules'

export default {
  components: {
    ApLoading,
    RulesStatusError,
    SegmentationList,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isError: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState('business-rules', {
      directorRule: (state) => state.directorRule,
      segmentationRule: (state) => state.directorRule.rules.Filter,
      multiMapRule: (state) => state.directorRule.rules.MultiMap,
    }),
  },
  async created() {
    this.getSegmentationRule()
  },
  methods: {
    ...mapActions('business-rules', ['getRule', 'assignLocalRuleToDirector']),
    async getSegmentationRule() {
      this.isLoading = true
      this.isError = false

      try {
        if (this.directorRule.rules?.Filter?.id) {
          await this.getRule(this.directorRule.rules.Filter.id)
        } else {
          const payload = {
            id: null,
            name: `${this.directorRule.name}: Segmentation Rule`,
            description: 'Manually created Segmentation rule',
            entities: 'Account',
            type: RULE_TYPES.segmentation,
            segmentations: this.multiMapRule.entities.map((el) => {
              return {
                entityName: el,
                conditions: [
                  {
                    field: '',
                    operator: '',
                    value: '',
                  },
                ],
              }
            }),
          }

          this.assignLocalRuleToDirector({ rule: payload })
        }
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<template>
  <v-combobox
    placeholder="Value"
    :value="value"
    outlined
    :multiple="isMultiple"
    dense
    required
    :items="items"
    :rules="rules"
    :disabled="isDisabled"
    :append-outer-icon="isDeleteVisible ? '$mdi-delete-outline' : undefined"
    append-icon="$mdi-chevron-down"
    color="accent"
    @change="$emit('input', $event)"
    @click:append-outer="$emit('delete')"
  >
    <template v-if="isOrVisible" #prepend>
      <div class="mt-1">Or</div>
    </template>

    <template v-if="isMultiple" #selection="{ attrs, item, selected }">
      <v-chip
        v-bind="attrs"
        color="light-grey"
        :input-value="selected"
        label
        small
        class="my-1"
      >
        {{ item }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isDeleteVisible: {
      type: Boolean,
      default: false,
    },
    isOrVisible: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, String],
      default: undefined,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

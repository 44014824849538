<template>
  <v-form ref="form">
    <ApLoading v-if="isLoading" class="mt-10" text="Loading metadata ...." />

    <template v-else>
      <v-card
        v-for="(entity, entityIndex) in segmentations"
        :key="entity.entityName"
        outlined
        class="pt-4 pb-2 px-6 mb-4"
      >
        <div class="text-h6 font-weight-bold">
          {{ entity.entityName }}
        </div>

        <div class="d-flex align-center mt-3">
          <v-icon size="18" color="ap-light-blue"> $mdi-filter </v-icon>
          <span class="text-body-2 ap-light-blue--text ml-1">
            Show only result for {{ entity.entityName }} if:
          </span>
        </div>

        <div class="mt-2">
          <div
            v-for="(condition, conditionIndex) in entity.conditions"
            :key="conditionIndex"
          >
            <div
              v-if="conditionIndex > 0"
              class="py-3 my-4 text-center font-weight-medium"
              style="position: relative"
            >
              <v-divider />
              <div
                style="position: absolute; top: 0; bottom: 0; right: 0; left: 0"
                class="d-flex align-center justify-center"
              >
                <span class="white px-3">And</span>
              </div>
            </div>

            <v-card outlined>
              <SegmentationListItem
                v-if="
                  rulesMetadata.Filter &&
                  rulesMetadata.Filter[entity.entityName]
                "
                :value="condition"
                :fields="rulesMetadata.Filter[entity.entityName]"
                @input="updateCondition(entityIndex, conditionIndex, $event)"
                @delete="deleteFilter(entityIndex, conditionIndex)"
              />
            </v-card>
          </div>
        </div>

        <div class="d-flex justify-end pt-2">
          <v-btn
            text
            small
            color="primary"
            class="ap-blue--text text-none font-weight-regular"
            @click="newFilter(entityIndex)"
          >
            <v-icon left> $mdi-plus </v-icon>
            Add new filter
          </v-btn>
        </div>
      </v-card>
    </template>
  </v-form>
</template>

<script>
import ApLoading from '@/components/common/ApLoading'
import SegmentationListItem from './SegmentationListItem'
import { mapState, mapActions } from 'vuex'
import { RULE_TYPES } from '@/api/business-rules/rules'
import Vue from 'vue'
import { cloneDeep, isEqual } from 'lodash-es'

const conditionDefaultStructure = {
  field: '',
  operator: '',
  value: '',
}

export default {
  components: {
    ApLoading,
    SegmentationListItem,
  },
  props: {
    rule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      fields: null,
      originalSegmentations: [],
      segmentations: [],
    }
  },
  computed: {
    ...mapState('business-rules', {
      isTriggeringSave: (state) =>
        state.directorRule.rules.Filter.isTriggeringSave,
      rulesMetadata: (state) => state.rulesMetadata,
    }),
  },
  watch: {
    async isTriggeringSave(value) {
      if (value) {
        if (this.$refs.form.validate()) {
          try {
            if (!isEqual(this.originalSegmentations, this.segmentations)) {
              if (this.rule.id) {
                await this.updateSegmentationRule({
                  rule: this.rule,
                  segmentations: this.segmentations,
                })
              } else {
                await this.createSegmentationRule({
                  directorRuleId: this.$route.params.directorRuleId,
                  rule: this.rule,
                  segmentations: this.segmentations,
                })
              }
            }

            this.$emit('saved')
          } finally {
            this.resetTriggerSaveCurrentRule(this.rule.type)
          }
        } else {
          this.resetTriggerSaveCurrentRule(this.rule.type)
        }
      }
    },
  },
  async created() {
    this.originalSegmentations = cloneDeep(this.rule.segmentations)
    this.segmentations = [...this.rule.segmentations]

    if (this.segmentations.length === 0) {
      this.segmentations = [
        {
          entityName: this.rule.entities,
          conditions: [{ ...conditionDefaultStructure }],
        },
      ]
    }

    const entitiesToRequest = []
    this.segmentations.forEach(async (segmentation) => {
      if (!this.rulesMetadata?.Filter?.[segmentation.entityName]) {
        entitiesToRequest.push(segmentation.entityName)
      }
    })

    if (entitiesToRequest.length > 0) {
      this.isLoading = true
      await this.getRuleEntityMetadata({
        entities: entitiesToRequest,
        ruleType: RULE_TYPES.segmentation,
      })
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions('business-rules', [
      'getRuleEntityMetadata',
      'resetTriggerSaveCurrentRule',
      'updateSegmentationRule',
      'createSegmentationRule',
    ]),
    updateCondition(entityIndex, conditionIndex, condition) {
      Vue.set(
        this.segmentations[entityIndex].conditions,
        conditionIndex,
        condition
      )
    },
    newFilter(entityIndex) {
      const entityConditions = this.segmentations[entityIndex].conditions

      entityConditions.push({ ...conditionDefaultStructure })
      Vue.set(this.segmentations, entityIndex, {
        ...this.segmentations[entityIndex],
        conditions: entityConditions,
      })
    },
    deleteFilter(entityIndex, conditionIndex) {
      const conditionsTemp = this.segmentations[entityIndex].conditions

      conditionsTemp.splice(conditionIndex, 1)

      Vue.set(this.segmentations, entityIndex, {
        ...this.segmentations[entityIndex],
        conditions: [...conditionsTemp],
      })
    },
  },
}
</script>
